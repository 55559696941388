.Loading{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.Loading__img{
    width: 50%;
    transition: ease-in;
}

